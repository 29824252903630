<template>
  <v-card class="brand_gift_card">
    <div @click="detailEvent" v-ripple style="width: 100%">
      <v-row no-gutters>
        <v-col cols="4" class="pa-1 img">
          <v-img :src="products.product_img" height="80px" width="80px" style="cursor: pointer; border-radius: 20px;" />
        </v-col>

        <v-col cols="8" class="align-self-center txt pl-2">
          <p class="name mb-0">
            {{ products.brand_name }}
            <!-- {{ row.product_name }} -->
          </p>
          <p class="product mb-0">
            {{ products.product_name }}
            <!-- {{ formatPrice(row.org_price) }} -->
          </p>
          <p class="money mb-0">
            <span class="percent">18%</span>
            {{ formatPrice(products.sale_price) }}
            <span>{{ formatPrice(row.org_price) }}</span>
          </p>
        </v-col>
      </v-row>
    </div>


  </v-card>
</template>

<script>
// import axios from '../../service/axios'

export default {
  name: "giftcard",
  props: {
    row: {
      type: Object,
      require: false
    }
  },

  data() {
    return {
      // imgSrc: "https://res.cloudinary.com/cycon/image/upload/v1631538567/coffee/star_ice_cafe_ny2sbb.jpg", 
      products: {},
      percentage: 10,

    }
  },

  mounted() {
    console.log('This props : ', this.$props.row)
    let pre_product = this.$props.row
    if (pre_product.article_code !== '') pre_product['product_name'] = pre_product.article_name

    let brandLogo = ''
    const starbucks =
      "https://res.cloudinary.com/cycon/image/upload/cafe_logo/starbucks_logo_wgbyhs.png";
    const ediya =
      "https://res.cloudinary.com/cycon/image/upload/v1651113576/cafe_logo/ediya_logo-01_gpuouw.jpg";
    const mega =
      "https://res.cloudinary.com/mothcar/image/upload/v1647413379/cafe_shop_logo/mega_shop_logo.png";
    const paik =
      "https://res.cloudinary.com/dw4yrr8eq/image/upload/v1651468441/paikslogo_vkzvxg.jpg";
    switch (pre_product.brand_name) {
      case "스타벅스":
        brandLogo = starbucks;
        break;
      case "이디야":
        brandLogo = ediya;
        break;
      case "메가커피":
        brandLogo = mega;
        break;
      case "빽다방":
        brandLogo = paik;
        break;
    }
    pre_product['brandImg'] = brandLogo
    // console.log('Pre product : ', pre_product)
    this.products = pre_product
  },

  methods: {
    formatPrice(value) {
      try {
        return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '원';
      }
      catch {
        return value + '원';
      }
    },
    updateFavorite() {
      // this.$props.row.favorite = !row.favorite
    },
    detailEvent() {
      // const params = {
      //     brandName: '스타벅스'
      // }
      // const product = await axios.get('/api/product/addToCart', {params: params})
      // // console.log('Get Product : ', product.data.data)
      this.$emit('childs-event', this.products);
      // console.log('Go buy....')
    },
  }
}
</script>

<style lang="scss">
.gift_title {
  font-size: 0.875rem;
  letter-spacing: -0.05em;
}

.org_price {
  font-size: 0.7rem;
  // text-decoration: line-through;
  letter-spacing: -0.03em;
}

.sale_price {
  font-size: 0.825rem;
  color: #5EB4F9;
  letter-spacing: -0.03em;
}

.brand_gift_card {
  border: .8px solid rgb(0 0 0 / 12%) !important;
}
</style>

<style lang="scss" scoped>
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.brand_gift_card {
  width: 100%;
  height: auto;
  display: flex;
  gap: 18px;
  border: unset !important;

  .img {
    width: auto;
    height: auto;
    @include flex-center;
  }

  .txt {
    width: 100%;
    height: auto;
    @include flex-column;
    justify-content: center;
    gap: 7px;

    p.name {
      font-size: 0.8125rem;
      color: #777;
    }

    p.product {
      font-size: 0.875rem;
      font-weight: 700;
    }

    p.money {
      font-size: 1rem;
      font-weight: 700;
      display: flex;
      gap: 5px;

      span {
      font-size: 0.8125rem;
      color: #ccc;
      text-decoration:line-through;
      }

      span.percent {
      color: #EF6855;
      font-size: 1rem;
      text-decoration: none;
      }
    }
  }
}
</style>
